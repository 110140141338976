.footer-sections {
  margin-top: 8%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap; 
}

.section {
  flex: 1;
  padding: 20px;
  text-align: center; 
}

.section h2 {
  font-size: 24px;
  color: red;
  font-family: 'Blanka Regular';
  margin-bottom: 20px;
}

.section p {
  font-size: 18px;
  color: black;
}

.divider {
  border-left: 2px dotted navy;
  height: auto;
  margin: 0 20px;
}

.footer-note-container {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.footer-note {
  font-size: 0.9em;
  color: #bcbcbc;
}

@media (max-width: 768px) {
  .footer-sections {
    flex-direction: column;
    align-items: center;
  }

  .section {
    flex: 1;
    padding: 10px;
    text-align: center; 
  }

  .divider {
    border-bottom: 2px dotted navy;
    width: 90%;
    margin: 0 20px;
  }

  .footer-note-container {
    margin-left: 120px;
    font-size: medium;
    width: 200px;
    margin-top: -10px;
  }
}
