.About-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.About-inner {
  text-align: center;
  width: 100%;
  max-width: 800px;
}

.About-us {
  font-size: 16px;
  line-height: 1.5;
}

.About-inquiry a {
  text-decoration: underline;
  transition: color 0.3s ease; /* Smooth transition */
}

.About-inquiry a:hover {
  color: red; /* Change color to red on hover */
}

.About-inquiry h1 {
  margin: 0; /* Remove default margin for h1 */
  padding: 0; /* Remove default padding for h1 */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
}

.image-container img {
  width: 100%; /* Set the width to 100% for responsiveness */
  max-width: 250px; /* Limit the maximum width of images */
  height: auto; /* Ensure the aspect ratio is maintained */
  margin: 10px;
}


.modal-about {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content-about {
  margin: auto;
  display: block;
  max-width: 90%;
  max-height: 90%;
}

.modal-content-about img {
  width: 100%;
  height: auto;
}

.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .About-inner {
    padding: 0 10px;
  }

  .About-us {
    font-size: 14px;
  }

  .modal-content-about {
    max-width: 100%;
    max-height: 100%;
  }
  .image-container img {
    max-width: 75%; /* Remove the maximum width limit for smaller screens */
  }
}
