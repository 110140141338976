.parent-container {
    display: inline;
    flex-direction: row;
    justify-content: space-around;
    min-height: 100vh;
}

.content {
    display: inline-block;
    flex-grow: 1;
    align-self: flex-start;
    flex-direction: column;
    align-items: center;
    margin: 100px 100px 100px 100px;
    gap: 20px;
    max-width: 500px;
    padding: 25px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); 
}

.contact-form {
    display: inline-block;
    flex-grow: 1;
    align-self: flex-end;
    flex-direction: column;
    align-items: center;
    margin: 80px 100px 100px 100px;
    gap: 20px;
    width: 100%;
    max-width: 500px;
    padding: 25px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); 
}

.header-images{
    text-align: center !important;
    background-color: #f5f5f5
}

.event-images{
    display: flex;
    justify-content: left;
    align-items: center;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding: 20px;
    background-color: #f5f5f5;
}
.event-images img {
    flex: 0 0 auto;
    width: 10%;
    height: 20;
    object-fit: cover;
    margin-right: 10px;
    scroll-snap-align: start;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.error {
    color: red;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input[type="submit"] {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

input[type="submit"]:hover {
    background-color: #444;
}

.success-message {
    color: green;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
}

@media (max-width: 768px) {
    .parent-container {
      flex-direction: column;
      align-items: center;
    }
  
    .content, .contact-form {
      margin: 20px;
      max-width: fit-content;
    }
    .contact-form {
        width: 80%; /* Adjust the width as needed */
        max-width: none; /* Remove the max-width restriction */
        margin: 20px auto; /* Center the form horizontally with some margin */
        margin-left: 2%;
    }
}