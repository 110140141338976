HomePage.css

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.main-content {
  margin-top: 2%;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.image-container {
  position: relative;
  padding: 10px; /* Adjust padding as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 120%;
  margin-top: -2%;
  margin-left: 9.7%;
  background-image: url('/Users/estradab/Projects/tacontigo/tacontigo-web/src/assets/patterns/pattern2.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2; /* Adjust opacity as needed */
}

.image-wrapper {
  position: relative;
  margin-right: 10px;
}

.image-wrapper img {
  width: 250px; /* Adjust the size as needed */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(245, 245, 245, 0.2);
}

.image-wrapper .overlay {
  position: absolute;
  top: 3%;
  left: 3.6%;
  width: 93%; /* Set width to cover the entire image */
  height: 93.5%; /* Set height to cover the entire image */
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;
  border-radius: 10px;
  text-align: center;
}

.image-wrapper:hover .overlay {
  opacity: 1;
}

.food-text {
  position: absolute;
  font-size: 45px;
  font-family: "Bangers", system-ui;
  color: #002fff;
  margin-left: 48%;
  margin-top: -2.2%;
}

.food-text2 {
  position: absolute;
  font-size: 50px;
  font-family: "Bangers", system-ui;
  color: #002fff;
  margin-left: 46%;
  margin-top: -1.9%;
  opacity: 0.4;
}

.button-74 {
  margin-top: 30px;
  background-color: #fbeee0;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-74:hover {
  background-color: #fff;
}

.button-74:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

.order-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.prev-button,
.next-button {
  background-color: white;
  border: 1.5px solid black; 
  border-radius: 50%;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  font-size: 24px; /* Adjust arrow size as needed */
  color: black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
  background-color: #1d6d8a;
}

.prev-button {
  position: absolute;
  left: 18%; /* Adjust position as needed */
  top: 50%;
  transform: translateY(-50%);
}

.next-button {
  position: absolute;
  right: 18%; /* Adjust position as needed */
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  body {
    flex-direction: column;
    align-items: flex-start;
  }

  .main-content {
    margin-top: 10%; /* Adjust spacing */
  }

  .pattern {
    width: 110%;
    height: 100%;
    margin-left: -10%;
  }

  .image-wrapper {
    margin-left: 17.5%;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
  }

  .image-wrapper .overlay {
    position: absolute; /* Adjusted position for overlay */
    top: 49.5%; /* Center vertically */
    left: 38.2%; /* Center horizontally */
    width: 75%; /* Adjusted width */
    height: 94%; /* Auto height */
    margin-left: 2.5%;
    transform: translate(-50%, -50%); /* Center the overlay */
    opacity: 0; /* Reduced opacity */
    transition: opacity 0.2s ease;
    text-align: center;
    border-radius: 10px;
  }

  .image-wrapper img {
    width: 100%;
    max-width: 250px; /* Ensure it does not exceed original size */
  }

  .food-text,
  .food-text2 {
    font-size: 25px; /* Adjust font size */
  }
  .food-text {
    margin-top: -10%;
    margin-left: 15%;
  }

  .food-text2 {
    margin-top: -8%;
    margin-left: 14%;
  }
  
  .button-74 {
    margin-top: 20px; /* Adjust spacing */
    padding: 10px 25px; /* Adjust padding */
    font-size: 16px; /* Adjust font size */
  }

  .order-button-container {
    margin-top: 20px; /* Adjust spacing */
  }

  .prev-button,
  .next-button {
    width: 30px; /* Adjust button size */
    height: 30px; /* Adjust button size */
    font-size: 18px; /* Adjust arrow size */
  }
  .prev-button {
    margin-left: -10%;
  }
  .next-button {
    margin-right: -10%;
  }
}


